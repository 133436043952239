import React, { useEffect, useState } from 'react'
import { TableComponent } from '../../components/table'
import {
  useFetchAvailableLocationsQuery,
  useUpdateAvailableLocationsMutation,
} from '../../redux-toolkits/user/user.slice'
import { Button } from '../../components/button'
import AddressInput from '../../components/inputs/addressInput'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import {
  Location,
  UpdateAvailableLocationsPayload,
} from '../../redux-toolkits/user/user.type'
import Switch from '../../components/switch'

export const Locations = () => {
  const [configuredLocations, setConfiguredLocations] = useState<
    (Location & {
      _id: string
      coordinates: number[]
    })[]
  >([])

  const {
    data,
    isLoading: fetchingLocations,
    refetch,
  } = useFetchAvailableLocationsQuery()

  const [updateAvailableLocations, { isLoading: updatingLocations }] =
    useUpdateAvailableLocationsMutation()

  useEffect(() => {
    if (data?.data) {
      const locations: (Location & {
        _id: string
        coordinates: number[]
      })[] = data?.data.docs.map((location) => {
        return {
          isAvailable: location.isAvailable,
          city: location.city,
          state: location.state,
          name: location.name,
          country: location.country,
          coordinates: location.location.coordinates,
          _id: location._id,
        }
      })
      setConfiguredLocations(locations)
    }
  }, [data])

  return (
    <div className="flex flex-col gap-10">
      <div className="flex items-center justify-between gap-4">
        <HeaderSubheader
          title="Available locations"
          text="Configure locations good is available in here."
          className=""
        />

        <form className="flex-1 flex gap-2 items-center justify-end">
          <div className="w-full md:max-w-[350px]">
            <AddressInput className={'!py-2'} />
          </div>

          <Button
            label={'Add new location'}
            primary
            onClick={() => {}}
            size="medium"
            icon="location"
            className="min-w-fit"
          />
        </form>
      </div>

      <TableComponent
        headers={['Location', 'City', 'State', ' ']}
        rows={
          data?.data.docs.map((item) => {
            return {
              id: item._id,
              content: [
                item.name,
                item.city,
                item.state,
                <button
                  disabled={updatingLocations}
                  className="flex items-center justify-end w-full"
                  key={`${item._id}-controls`}
                  onClick={() => {
                    const locationsCopy: (Location & {
                      _id: string
                      coordinates: number[]
                    })[] = JSON.parse(JSON.stringify(configuredLocations))

                    const locationToUpdate = locationsCopy.find(
                      (location) => location._id === item._id,
                    )

                    if (locationToUpdate) {
                      locationToUpdate.isAvailable =
                        !locationToUpdate.isAvailable
                    }

                    const payload = {
                      locations: locationsCopy.map(({ _id, ...rest }) => rest),
                    }
                    updateAvailableLocations(payload)
                  }}
                >
                  <Switch
                    checked={item.isAvailable}
                    disabled={updatingLocations}
                  />
                </button>,
              ],
            }
          }) ?? []
        }
        name="available-locations-table"
        isRowClickable={false}
        loading={fetchingLocations}
        isEmpty={data?.data && data?.data.docs.length < 1}
        emptyText="No location has been configured"
      />
    </div>
  )
}

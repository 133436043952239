import React from 'react'

export const ImageBanner = ({
  loading,
  imageUrl,
  className,
}: {
  loading?: boolean
  imageUrl: string
  className?: string
}) => {
  return (
    <div
      style={{ backgroundImage: !loading ? `url('${imageUrl}')` : '' }}
      className={`
                    h-[120px] md:h-[240px] lg:h-[400px]
                    w-full
                    rounded-md
                    bg-cover
                    ${loading && 'animate-pulse !bg-gray-300'}
                    ${
                      !imageUrl &&
                      '!bg-light-gray-bg !border-divider-gray border'
                    }
                    ${className}
                `}
    ></div>
  )
}

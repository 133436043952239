import React from 'react'
import { registerables, Chart } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { PRIMARY_COLOR } from '../../constants'

interface Props {
  labels: string[]
  data: number[]
  width?: number
  height?: number
  responsive: boolean
}

Chart.register(...registerables)

const LineChart: React.FC<Props> = ({
  data,
  labels,
  width = 500,
  height = 150,
  responsive,
}) => {
  return (
    <Line
      datasetIdKey="line"
      data={{
        labels,

        datasets: [
          {
            label: '',
            fill: true,
            backgroundColor: 'transparent',
            borderColor: PRIMARY_COLOR,
            type: 'line',
            tension: 0.5,
            pointBackgroundColor: PRIMARY_COLOR,
            pointRadius: 3,
            data,
          },
        ],
      }}
      options={{
        responsive,
        scales: {
          x: {
            grid: {
              display: true,
            },
            border: {
              display: false,
            },
            display: true,
          },
          y: {
            grid: {
              display: true,
            },
            display: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      width={width}
      height={height}
    />
  )
}

export default LineChart

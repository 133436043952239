import React, { ReactNode } from 'react'

export const ChartCard: React.FC<{
  title: string
  value?: string
  chart: ReactNode
}> = ({ title, value = '', chart }) => {
  return (
    <div
      className="
      p-8
      bg-light-gray-bg
      rounded-xl
        "
    >
      <span
        className="
        font-recoleta-bold text-xl
        block mb-3
        "
      >
        {title}
      </span>

      <span
        className="
        font-medium text-base
        block mb-9
        "
      >
        {value}
      </span>

      {chart}
    </div>
  )
}

import React from 'react'
import Layout from '../../layout'
import { useGetOrderQuery } from '../../redux-toolkits/orders/orders.slice'
import { useParams } from 'react-router-dom'
import Spinner from '../../components/spinner/Spinner'
import { formatCurrency } from '../../helpers'
import { formatDate } from 'date-fns'

// interface OrderDetailsPageProps {}

const OrderDetailsPage: React.FC = () => {
  const { id } = useParams()

  if (!id) return <Spinner />

  const {
    data: payload,
    isLoading,
    isError,
    isSuccess,
  } = useGetOrderQuery({ id })

  if (isLoading) {
    return <Spinner />
  } else if (isError) {
    return <p>Oops! something went wrong.</p>
  }

  return (
    <Layout>
      {isSuccess && (
        <div>
          <div className="flex justify-between">
            <h1 className="text-2xl font-bold capitalize">Order Details</h1>

            <p className="text-2xl">
              Order Price: <b>{formatCurrency(payload?.data?.order.amount)}</b>
            </p>
          </div>

          <div className="my-10 grid grid-cols-2 gap-10">
            <div className="bg-white p-5 shadow-3xl flex justify-between">
              <div className="space-y-4">
                <div>
                  <label className="text-sm">Total amount</label>
                  <p className="text-1xl">
                    {formatCurrency(payload?.data?.order.amount)}
                  </p>
                </div>
                <div>
                  <label className="text-sm">Order status</label>
                  <p className="text-1xl">{payload?.data?.order.status}</p>
                </div>

                <div>
                  <label className="text-sm">Payment status</label>
                  <p className="text-1xl">
                    {payload?.data?.order.paymentStatus}
                  </p>
                </div>
                <div>
                  <label className="text-sm">Buy for self</label>
                  <p className="text-1xl">
                    {payload?.data?.order.buyForSelf ? 'Yes' : 'No'}
                  </p>
                </div>
              </div>
              <div className="space-y-4">
                <div>
                  <label className="text-sm">Order number</label>
                  <p className="text-1xl">{payload?.data?.order.orderNumber}</p>
                </div>

                <div>
                  <label className="text-sm">Service Charge</label>
                  <p className="text-1xl">
                    {payload?.data?.order.serviceCharge}
                  </p>
                </div>

                <div>
                  <label className="text-sm">Order date</label>
                  <p className="text-1xl">
                    {formatDate(payload?.data?.order.createdAt, 'dd/mm/yyyy')}
                  </p>
                </div>

                <div>
                  <label className="text-sm">Delivery fee</label>
                  <p className="text-1xl">
                    {payload.data.order.delivery.charge}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white p-5 shadow-3xl">
              <p className=" font-bold py-2">Delivery</p>
              <hr />
              <div className="flex justify-between py-2">
                <div>
                  <label className="text-sm">Delivery contact</label>
                  <p className="text-1xl">
                    {payload.data.order.delivery.contact}
                  </p>
                </div>
                <div>
                  <label className="text-sm">Delivery type</label>
                  <p className="text-1xl">{payload.data.order.delivery.type}</p>
                </div>
              </div>

              <p className=" font-bold py-2">Personal Information</p>
              <hr />
              <div className="flex justify-between py-2">
                <div>
                  <label className="text-sm">First name</label>
                  <p className="text-1xl">
                    {payload.data.order.personalInformation.firstName}
                  </p>
                </div>
                <div>
                  <label className="text-sm">Last name</label>
                  <p className="text-1xl">
                    {payload.data.order.personalInformation.lastName}
                  </p>
                </div>
                <div>
                  <label className="text-sm">Phone</label>
                  <p className="text-1xl">
                    {payload.data.order.personalInformation.phone}
                  </p>
                </div>
              </div>
              <div className="flex justify-between py-2">
                <div>
                  <label className="text-sm">Email</label>
                  <p className="text-1xl">
                    {payload.data.order.personalInformation.email}
                  </p>
                </div>
                <div>
                  <label className="text-sm">Email Notifications</label>
                  <p className="text-1xl">
                    {payload.data.order.personalInformation.emailNotifications
                      ? 'Yes'
                      : 'No'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-5">
            <p className="py-2">Order Items</p>
            <hr />

            <table className="border-collapse w-full text-left text-sm mt-5">
              <thead className="text-xs font-normal text-gray-400">
                <tr>
                  <th className="p-3 px-5">Name</th>
                  <th className="p-3 px-5">Price</th>
                  <th className="p-3 px-5">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {payload.data.orderItems.map((item) => (
                  <tr className="border-t hover:shadow-md group" key={item._id}>
                    <td className="p-6">{item.product.name}</td>
                    <td className="p-6">
                      {formatCurrency(item.product.price)}
                    </td>
                    <td className="p-6">{item.quantity}</td>
                    <td className="p-6">
                      <img
                        src={item.product.image}
                        className="h-20 w-20"
                        alt={item.product.name}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default OrderDetailsPage

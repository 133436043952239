import React from 'react'
import { Formik } from 'formik'
import { useRegisterUserMutation } from '../../redux-toolkits/auth/auth.slice'
import CustomForm from './Form'
import { UserFormSchema } from './validate'

const UserForm: React.FC = (): JSX.Element => {
  const [registerUser, { isLoading }] = useRegisterUserMutation()

  return (
    <Formik
      initialValues={{
        firstname: '',
        lastname: '',
        email: '',
        password: '',
      }}
      validationSchema={UserFormSchema}
      onSubmit={async (values) => {
        try {
          await registerUser(values)
          console.log(registerUser)
        } catch (error) {
          console.log(error)
        }
      }}
    >
      {() => <CustomForm isLoading={isLoading} />}
    </Formik>
  )
}

export default UserForm

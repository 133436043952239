import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
  UpdateProductStockPayload,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  AddProductVariantSchema,
  CreateCategorySchema,
  CreateTagSchema,
  UpdateProductSchema,
} from '../../../features/users/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
  useUpdateItemStockMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import {
  ComboItem,
  CreatedProductVariant,
  Product,
  ProductVariant,
  SingleVariant,
} from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'

const reasons = [
  {
    name: 'Inventory re-count',
    value: 'Inventory re-count',
  },
  {
    name: 'Damage',
    value: 'Damage',
  },
  {
    name: 'Loss',
    value: 'Loss',
  },
  {
    name: 'Stock received',
    value: 'Stock received',
  },
  {
    name: 'Others',
    value: 'Others',
  },
  {
    name: 'Restock return',
    value: 'Restock return',
  },
  {
    name: 'Theft',
    value: 'Theft',
  },
]

export const UpdateItemStockModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: () => void
  product?: Product
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = () => {},
  product,
}) => {
  const [updateStock, { isLoading }] = useUpdateItemStockMutation()

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      reason: {
        name: '',
        value: '',
      },
      quantity: product?.inStockQuantity ?? '',
      price: product?.price ?? '',
    },
    enableReinitialize: true,
    validationSchema: UpdateProductSchema,
    onSubmit: (values, { resetForm }) => {
      const payload: UpdateProductStockPayload = {
        adjustedQuantity: Number(values.quantity),
        adjustInventory: true,
        adjustmentReason: values.reason.value,
        price: Number(values.price),
        increaseStock: true,
      }

      updateStock({ id: product?._id ?? '', payload }).then((resp) => {
        if (resp.data?.success) {
          onSuccessCallback && onSuccessCallback()
          resetForm()
        }
      })
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={'Update product stock'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-4">
          <TextInput
            type="text"
            name="price"
            placeholder="Adjuested price"
            value={String(values.price)}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.price && touched.price ? errors.price : ''}
            hasIcon={false}
          />

          <TextInput
            type="text"
            name="quantity"
            placeholder="Adjusted quantity"
            value={String(values.quantity)}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.quantity && touched.quantity ? errors.quantity : ''}
            hasIcon={false}
          />

          <Dropdown
            className="w-full"
            menuClassName="max-h-[200px]"
            widthClass="!w-full"
            menuButton={
              <TextInput
                type="text"
                name="reason"
                placeholder="Adjustment reason"
                value={values.reason.name}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={
                  errors.reason?.value && touched.reason?.value
                    ? errors.reason.value
                    : ''
                }
                hasIcon={false}
                readOnly
                extra={
                  <Icon
                    id={'left-caret'}
                    width={16}
                    height={16}
                    className="-rotate-90 text-sec-black"
                  />
                }
                className="!border-divider-gray !w-full"
              />
            }
            onClickMenuItem={(selection) => {
              setFieldValue('reason', selection)
            }}
            menuItems={reasons}
          />
        </div>

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid || isLoading}
          loading={isLoading}
          primary
        />
      </form>
    </ModalComponent>
  )
}

import React, { useEffect, useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { useUploadImageMutation } from '../../../redux-toolkits/misc/misc.slice'
import {
  useGetAllProductsQuery,
  useLazyGetAllProductsQuery,
  useLazyGetAllProductTagsQuery,
} from '../../../redux-toolkits/products/products.slice'
import { Product, QueryProps } from '../../../types/types'
import { TextInput } from '../../inputs/textInput'
import { debounce } from 'lodash'
import { LoadingOval } from '../../spinner/Spinner'
import { PRIMARY_COLOR } from '../../../constants'
import { formatCurrency } from '../../../helpers'
import { Checkbox } from '../../inputs/Checkbox'
import { Tag } from '../../../redux-toolkits/products/products.type'
import { EmptyState } from '../../emptyState/emptyState'

const handleSearch = (searchFunction: (val: any) => void, query: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

const defaultquery = {
  search: '',
  page: '1',
  pageSize: '100',
  skip: '0',
  limit: '100',
}

export const SelectTagsModal = ({
  open,
  setOpen,
  onCloseCallback,
  onSuccessCallback,
  selectedTags = [],
}: {
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: Tag[]) => void
  selectedTags?: Tag[]
}) => {
  const [queryParam, setQueryParam] = useState<QueryProps>(defaultquery)
  const [tags, setTags] = useState<Tag[]>(selectedTags)

  const [getAllTags, { data, isFetching }] = useLazyGetAllProductTagsQuery()

  useEffect(() => {
    open && handleDebouncedSearch(getAllTags, queryParam)
  }, [queryParam.search, open])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        onCloseCallback && onCloseCallback()
        setQueryParam(defaultquery)
        setTags(selectedTags)
      }}
      title="Manage Tags"
    >
      <div
        className="
        h-screen max-h-[500px]
        flex flex-col gap-4
      "
      >
        <TextInput
          name="search"
          onChange={(e) => {
            setQueryParam({ ...queryParam, search: e.target.value })
          }}
          placeholder="Type to search..."
          iconSvg="search"
          value={queryParam.search}
          className="!bg-gray-bg !rounded-[32px] 
            !border-transparent !text-sec-black !py-3"
        />

        {/* Products  */}
        <div className="flex-1 overflow-y-scroll">
          {isFetching ? (
            <div className="w-full flex justify-center pt-10">
              <LoadingOval
                loaderWidth="25"
                loaderHeight="25"
                color={PRIMARY_COLOR}
              />
            </div>
          ) : (
            <div>
              {data?.data?.data?.length && data?.data?.data?.length > 0 ? (
                <div className="flex flex-col">
                  {data?.data?.data?.map((tag, idx) => {
                    const key = `procuct-selection-option-${idx}-${tag._id}`
                    return (
                      <div
                        key={key}
                        className="
                        flex gap-2 items-center
                        border-b border-divider-gray
                        py-2
                        first:pt-0
                      "
                      >
                        <div
                          className="
                          flex-1 
                        "
                        >
                          <span
                            className="
                            group
                            cursor-pointer
                            relative
                            text-sm text-black
                            font-normal
                            "
                          >
                            {tag.name}
                          </span>
                        </div>

                        <Checkbox
                          checkStatus={selectedTags
                            .map((tag) => tag._id)
                            .includes(tag._id)}
                          handleChange={(checked) => {
                            if (checked) {
                              const tagsCopy: Tag[] = JSON.parse(
                                JSON.stringify(tags),
                              )
                              tagsCopy.push(tag)

                              console.log(tagsCopy)
                              setTags(tagsCopy)
                            } else {
                              const tagsCopy: Tag[] = JSON.parse(
                                JSON.stringify(tags),
                              )
                              setTags(
                                tagsCopy.filter(
                                  (singleTag) => tag._id !== singleTag._id,
                                ),
                              )
                            }
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              ) : (
                <EmptyState text="No tags found" />
              )}
            </div>
          )}
        </div>

        <Button
          type="button"
          primary
          label="Done"
          onClick={() => {
            onSuccessCallback && onSuccessCallback(tags)
          }}
        />
      </div>
    </ModalComponent>
  )
}

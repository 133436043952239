import React from 'react'
import ModalComponent from '..'
import { TextInput } from '../../inputs/textInput'
import { useFormik } from 'formik'
import { Button } from '../../button'
import {
  CreateCategoryPayload,
  Tag,
} from '../../../redux-toolkits/products/products.type'
import {
  AddComboItemSchema,
  CreateCategorySchema,
  CreateTagSchema,
} from '../../../features/users/validate'
import {
  useCreateProductCategoryMutation,
  useCreateProductTagMutation,
  useEditProductCategoryMutation,
  useEditProductTagMutation,
} from '../../../redux-toolkits/products/products.slice'
import { ListCategoryDoc } from '../../../redux-toolkits/lists/list.types'
import { ComboItem } from '../../../types/types'
import { Checkbox } from '../../inputs/Checkbox'

export const AddComboItemModal: React.FC<{
  open: boolean
  setOpen: (cal: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: ComboItem) => void
  mode: 'edit' | 'create'
  comboItem?: ComboItem
}> = ({
  open,
  setOpen,
  onCloseCallback = () => {},
  onSuccessCallback = (val) => {},
  mode,
  comboItem,
}) => {
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    isValid,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: comboItem?.name ?? '',
      price: comboItem?.price ?? '',
      minimumQuantity: comboItem?.minimumQuantity ?? '',
      maximumQuantity: comboItem?.maximumQuantity ?? '',
      required: true,
    },
    enableReinitialize: true,
    validationSchema: AddComboItemSchema,
    onSubmit: (values, { resetForm }) => {
      const itemToAdd: ComboItem = {
        name: values.name,
        price: Number(values.price),
        isProduct: false,
        minimumQuantity: Number(values.minimumQuantity),
        maximumQuantity: Number(values.maximumQuantity),
        required: values.required,
      }

      onSuccessCallback(itemToAdd)
      resetForm()
    },
  })
  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        onCloseCallback()
      }}
      title={mode === 'create' ? 'Add new combo item' : 'Edit combo item'}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <TextInput
            type="text"
            name="name"
            placeholder="Name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.name && touched.name ? errors.name : ''}
            hasIcon={false}
          />

          <TextInput
            type="text"
            name="price"
            placeholder="Price"
            value={String(values.price)}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={errors.price && touched.price ? errors.price : ''}
            hasIcon={false}
          />

          <TextInput
            type="text"
            name="minimumQuantity"
            placeholder="Minimum quantity"
            value={String(values.minimumQuantity)}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={
              errors.minimumQuantity && touched.minimumQuantity
                ? errors.minimumQuantity
                : ''
            }
            hasIcon={false}
          />

          <TextInput
            type="text"
            name="maximumQuantity"
            placeholder="Maximum quantity"
            value={String(values.maximumQuantity)}
            onBlur={handleBlur}
            onChange={handleChange}
            errors={
              errors.maximumQuantity && touched.maximumQuantity
                ? errors.maximumQuantity
                : ''
            }
            hasIcon={false}
          />

          <Checkbox
            text="This is a required item"
            checkStatus={values.required}
            handleChange={(status) => {
              setFieldValue('required', status)
            }}
          />
        </div>

        <Button
          label="Proceed"
          type="submit"
          className="mt-10"
          disabled={!isValid}
          loading={false}
          primary
        />
      </form>
    </ModalComponent>
  )
}

import * as Yup from 'yup'

export const UserFormSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
})

export const EditUserFormSchema = Yup.object().shape({
  firstname: Yup.string().required('First name is required'),
  lastname: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required'),
})

export const CreateCategorySchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
  description: Yup.string().required('Category description is required'),
})

export const CreateTagSchema = Yup.object().shape({
  name: Yup.string().required('Tag name is required'),
  description: Yup.string().required('Tag description is required'),
})

export const AddComboItemSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  price: Yup.number().min(1).required('Price is required'),
  minimumQuantity: Yup.number().min(0).required('Minimum quantity is required'),
  maximumQuantity: Yup.number().min(1).required('Maximum quantity is required'),
  required: Yup.boolean().required('Required'),
})

export const AddProductVariantSchema = Yup.object().shape({
  variant: Yup.string().required('Variant is required'),
  value: Yup.string().required('Value is required'),
  price: Yup.number().min(1).required('Price is required'),
})

export const UpdateProductSchema = Yup.object().shape({
  reason: Yup.object({
    value: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Reason is required'),
  quantity: Yup.string().required('Quantity is required'),
  price: Yup.number().min(1).required('Price is required'),
})

export const CreateVariantSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
})

export const CreateProductSchema = Yup.object().shape({
  name: Yup.string().required('Product name is required'),
  category: Yup.object({
    _id: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Category is required'),
  description: Yup.string().required('Product description is required'),
  images: Yup.array()
    .of(Yup.string())
    .required('Upload at least one image for this product'),
  price: Yup.string().required('Product price is required'),
  quantityInStock: Yup.string().required('Product stock quantity is required'),
  vendor: Yup.object({
    _id: Yup.string().required(),
    name: Yup.string().required(),
  }).required('Vendor is required'),
  tags: Yup.array().of(
    Yup.object({
      _id: Yup.string().required(),
      name: Yup.string().required(),
    }),
  ),
  isCombo: Yup.boolean(),
  comboItems: Yup.array().of(
    Yup.object({
      name: Yup.string().required(),
      price: Yup.number().required(),
      minimumQuantity: Yup.number().required(),
      maximumQuantity: Yup.number().required(),
    }),
  ),
  isVariant: Yup.boolean(),
  variants: Yup.array().of(
    Yup.object({
      price: Yup.number().required(),
      value: Yup.string().required(),
      variant: Yup.object({
        name: Yup.string().required(),
        _id: Yup.string().required(),
      }),
    }),
  ),
  lowStockAlert: Yup.boolean().required(),
  lowStockQuantity: Yup.string().when(
    'lowStockAlert',
    (lowStockAlert, schema) => {
      return lowStockAlert ? schema.required() : schema.notRequired()
    },
  ),
})

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import {
  type BaseQueryApi,
  createApi,
  type FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import {
  BACKEND_BASE_URL,
  COOKIE_KEY,
  GOOD_ONLINE_BACKEND_BASE_URL,
  IMAGES_BACKEND_BASE_URL,
} from '../../constants'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

const baseQuery = fetchBaseQuery({
  baseUrl: BACKEND_BASE_URL,
  prepareHeaders: (headers) => {
    const cookie = Cookies.get(COOKIE_KEY)! || '{}'
    const token = JSON.parse(cookie)?.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: Record<string, unknown>,
): Promise<any> => {
  const result = await baseQuery(args, api, extraOptions)

  const isError = result.error?.status === 401
  // if token is expired
  if (isError) {
    Cookies.remove(COOKIE_KEY)
    localStorage.removeItem('persist:root')
    window.location.replace('/login')
  }

  // If internal server error
  if (result.meta?.response?.status === 500) {
    toast.error('Internal server error')
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['ORDER', 'USER'],
  endpoints: (builder) => ({}),
})

export const goodOnlineApiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: GOOD_ONLINE_BACKEND_BASE_URL,
  }),
  endpoints: (builder) => ({}),
  reducerPath: 'goodOnlineApi',
})

export const imagesApiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: IMAGES_BACKEND_BASE_URL,
  }),
  endpoints: (builder) => ({}),
  reducerPath: 'imagesApi',
})

import React, { useState } from 'react'
import { useFetchListCategoriesQuery } from '../../redux-toolkits/lists/list.slice'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'

import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import { useModalControl } from '../../hooks/useModalControl'
import { AddCategoryModal } from '../../components/modal/app_modals/addCategoryModal'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { ListCategoryDoc } from '../../redux-toolkits/lists/list.types'
import { useGetVariantsQuery } from '../../redux-toolkits/products/products.slice'
import { SingleVariant } from '../../types/types'
import { CreateVariantModal } from '../../components/modal/app_modals/createVariantModal'

const Variants: React.FC = () => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })

  const [selectedVariant, setSelectedVariant] = useState<SingleVariant>()
  const [mode, setMode] = useState<'create' | 'edit'>('create')

  const { setModalOpen, modalOpen, handleModalOpen, handleModalClose, modal } =
    useModalControl()

  const { data, isFetching, refetch } = useGetVariantsQuery({
    skip: query.skip.toString(),
    limit: query.limit.toString(),
  })

  return (
    <div>
      <PageHeader title="Variants" />

      <div className="w-full flex justify-end">
        <Button
          icon="plus"
          size="medium"
          label="Add new product variant"
          primary
          onClick={() => {
            setMode('create')
            handleModalOpen('addVariantModal')
          }}
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total variants count"
          count={data?.data.total ?? 0}
        />
      </CountCardContainer>

      <TableComponent
        headers={['Name', 'Variant ID', ' ']}
        rows={
          data?.data.docs.length
            ? data.data.docs.map((singleVariant) => {
                return {
                  id: singleVariant._id,
                  content: [
                    <div key="">{singleVariant.name}</div>,
                    <div key="">{singleVariant._id}</div>,
                    <Dropdown
                      key={`${singleVariant._id}-controls`}
                      menuButton={
                        <Icon
                          id="ellipses"
                          height={18}
                          width={18}
                          className=""
                        />
                      }
                      onClickMenuItem={() => {}}
                      menuItems={[
                        {
                          name: (
                            <button
                              className="disabled:opacity-30 w-full text-left"
                              onClick={(): any => {
                                setMode('edit')
                                handleModalOpen('addVariantModal')
                                setSelectedVariant(singleVariant)
                              }}
                            >
                              Edit
                            </button>
                          ),
                          value: '',
                        },
                      ]}
                    />,
                  ],
                }
              })
            : []
        }
        name="variants-table"
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.docs.length)}
        currentPage={query.skip / query.limit + 1}
        totalDataCount={data?.data.total ?? 0}
        pageLimit={query.limit}
        onPageChange={(number) => {
          setQuery({ ...query, skip: (number - 1) * query.limit })
        }}
      />

      <CreateVariantModal
        open={modalOpen && modal === 'addVariantModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedVariant(undefined)
        }}
        onSuccessCallback={() => {
          setSelectedVariant(undefined)
          refetch()
        }}
        mode={mode}
        variant={selectedVariant}
      />
    </div>
  )
}

export default Variants

import React, { ChangeEvent, useEffect, useState } from 'react'
import Layout from '../../layout'
import { PageHeader } from '../../components/typography/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '../../components/button'
import { HeaderSubheader } from '../../components/typography/HeaderSubheader'
import Icon from '../../components/icon'
import { AddNewCard } from '../../components/cards/AddNewCard'
import { Field, useFormik } from 'formik'
import { TextInput } from '../../components/inputs/textInput'
import { useFetchListCategoriesQuery } from '../../redux-toolkits/lists/list.slice'
import Dropdown from '../../components/inputs/dropdown'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'
import {
  useGetAllVendorsQuery,
  useLazyGetAllVendorsQuery,
} from '../../redux-toolkits/vendors/vendors.slice'
import { debounce } from 'lodash'
import { Checkbox } from '../../components/inputs/Checkbox'
import { SelectProductModal } from '../../components/modal/app_modals/selectProductModal'
import { useModalControl } from '../../hooks/useModalControl'
import {
  ComboItem,
  CreatedProductVariant,
  Product,
  ProductVariant,
} from '../../types/types'
import { removeElementAtIndex } from '../../helpers'
import { SelectTagsModal } from '../../components/modal/app_modals/selectTagsModal'
import {
  CreateProductPayload,
  Tag,
} from '../../redux-toolkits/products/products.type'
import {
  useCreateProductMutation,
  useGetVariantsQuery,
  useLazyGetSingleProductQuery,
} from '../../redux-toolkits/products/products.slice'
import Spinner from '../../components/spinner/Spinner'
import { ImageUploadButton } from '../../components/button/imageUploadButton'
import { AddComboItemModal } from '../../components/modal/app_modals/addComboItemModal'
import { AddProductVariantModal } from '../../components/modal/app_modals/addVariantModal'
import { ImageUploadPreviewModal } from '../../components/modal/app_modals/ImagePreviewModal'
import { CreateProductSchema } from '../users/validate'

const handleSearch = (searchFunction: (val?: any) => void, query?: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

export const CreateProductPage = () => {
  const { id } = useParams<{ id: string }>()

  const [vendorSearch, setVendorSearch] = useState<string>()
  const [selectedComboItem, setSelectedComboItem] = useState<{
    index: number
    item: ComboItem
  }>()
  const [selectedVariant, setSelectedVariant] = useState<{
    index: number
    item: CreatedProductVariant
  }>()
  const [uploadedImage, setUploadedImage] = useState<File>()

  const navigate = useNavigate()

  const { isLoading: loadingCategories, data: categories } =
    useFetchListCategoriesQuery({
      skip: 0,
      limit: 100,
    })

  const { isLoading: loadingVariants, data: variants } = useGetVariantsQuery({
    skip: '0',
    page: '1',
    limit: '100',
  })

  const [fetchSingleProduct, { isFetching: fetchingSingleProduct }] =
    useLazyGetSingleProductQuery()

  const [getAllVendors, { data: vendors, isFetching: fetchingVendors }] =
    useLazyGetAllVendorsQuery()

  const { handleModalOpen, setModalOpen, modal, modalOpen, handleModalClose } =
    useModalControl()

  const [createProduct, { isLoading: creatingProduct }] =
    useCreateProductMutation()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    setUploadedImage(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  const initialValues: {
    name: string
    category: {
      _id: string
      name: string
    }
    description: string
    images: string[]
    price: string
    quantityInStock: string
    vendor: {
      _id: string
      name: string
    }
    tags: Tag[]
    isCombo: boolean
    comboItems: ComboItem[]
    isVariant: boolean
    variants: CreatedProductVariant[]
    lowStockAlert: boolean
    lowStockQuantity: string
  } = {
    name: '',
    category: {
      _id: '',
      name: '',
    },
    description: '',
    images: [],
    price: '',
    quantityInStock: '',
    vendor: {
      _id: '',
      name: '',
    },
    tags: [],
    isCombo: false,
    comboItems: [],
    isVariant: false,
    variants: [],
    lowStockAlert: false,
    lowStockQuantity: '0',
  }
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateProductSchema,
    onSubmit: (values, { resetForm }) => {
      const payload: CreateProductPayload = {
        name: values.name,
        categoryId: values.category._id,
        description: values.description,
        images: values.images,
        isActive: true,
        trackQuantity: true,
        price: Number(values.price),
        inStockQuantity: Number(values.quantityInStock),
        vendorId: values.vendor._id,
        tags: values.tags.map((tag) => tag._id),
        isCombo: values.isCombo,
        comboItems: values.comboItems,
        isVariant: values.isVariant,
        variants: values.variants.map((variant) => {
          return {
            variant: variant.variant._id,
            price: variant.price,
            value: variant.value,
          }
        }),
        lowStockAlert: values.lowStockAlert,
      }

      if (payload.lowStockAlert) {
        payload.lowStockQuantity = Number(values.lowStockQuantity)
      }

      createProduct(payload).then((resp) => {
        if (resp.data?.success) {
          resetForm()
          navigate(`/products/view/${resp.data.data._id}`)
        }
      })
    },
  })

  useEffect(() => {
    handleDebouncedSearch(getAllVendors, {
      page: `1`,
      limit: NUMBER_OF_ITEMS_PER_PAGE.toString(),
      search: vendorSearch,
    })
  }, [vendorSearch])

  useEffect(() => {
    console.log(errors)
  }, [errors])

  useEffect(() => {
    if (id) {
      fetchSingleProduct({ id }).then((resp) => {
        if (resp.data?.success) {
          const product = resp.data.data
          setFieldValue('name', product.name)
          setFieldValue('category', {
            _id: product.category._id,
            name: product.category.name,
          })
          setFieldValue('description', product.description)
          setFieldValue('images', product.images ? [...product.images] : [])
          setFieldValue('price', product.price)
          setFieldValue('vendor', {
            _id: product.vendor._id,
            name: product.vendor.email,
          })
          setFieldValue('quantityInStock', product.inStockQuantity)
          setFieldValue('tags', product.tags)
          setFieldValue('isCombo', product.comboItems.length > 0)
          setFieldValue(
            'comboItems',
            product.comboItems.map(({ _id, ...others }) => {
              return { ...others }
            }),
          )
          setFieldValue('isVariant', product.variants.length > 0)
          setFieldValue(
            'variants',
            product.variants.map(({ _id, ...others }) => {
              return { ...others }
            }),
          )
          setFieldValue('lowStockAlert', product.lowStockAlert)
          setFieldValue('lowStockQuantity', product.lowStockQuantity)
        }
      })
    }
  }, [id])

  return (
    <Layout>
      {id && fetchingSingleProduct ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          <PageHeader title={id ? 'Edit product' : 'Create product'} />

          <form className="flex flex-col gap-10" onSubmit={handleSubmit}>
            {!id && (
              <div className="flex w-full justify-end">
                <Button
                  type="submit"
                  primary
                  label="Save"
                  className="!w-full !max-w-[150px]"
                  loading={creatingProduct}
                  disabled={!isValid || creatingProduct}
                />
              </div>
            )}

            {/* IMAGES  */}
            <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
              <HeaderSubheader
                title="Product Images"
                text="Upload product  image(s)."
              />

              <div
                className="
                grid grid-flow-col !auto-cols-[minmax(300px,300px)]
                gap-4
                overflow-x-scroll
                mt-4
                "
              >
                {values.images.map((imageUrl, idx) => {
                  const key = `productImage-${idx}`
                  return (
                    <div key={key} className="w-full flex flex-col gap-2">
                      <div className="flex items-center justify-end">
                        <button
                          type="button"
                          className="p-2 bg-danger/10 rounded-full"
                          onClick={() => {
                            const imageList: string[] = JSON.parse(
                              JSON.stringify(values.images ?? []),
                            )
                            setFieldValue(
                              'images',
                              removeElementAtIndex(imageList, idx),
                            )
                          }}
                        >
                          <Icon
                            className="text-danger"
                            width={16}
                            height={16}
                            id="bin"
                          />
                        </button>
                      </div>
                      <div>
                        <img
                          src={imageUrl}
                          alt={`product-${idx}`}
                          className="
                          border h-[300px] w-full 
                          object-cover
                          rounded-lg overflow-hidden
                          "
                        />
                      </div>
                    </div>
                  )
                })}
                <AddNewCard
                  onClick={() => {}}
                  className="min-h-[300px] !cursor-default"
                  customChildren={
                    <ImageUploadButton
                      showText={false}
                      handleChange={handleFileChange}
                      className="
                      !relative
                      !border-0
                      !bg-white
                      !top-0 !right-0
                      !w-[50px] !h-[50px]
                      flex items-center justify-center
                      "
                    />
                  }
                />
              </div>
            </div>

            {/* GENERAL DETAILS */}
            <div
              className="
              flex flex-col gap-6 
              pb-10 border-b border-divider-gray"
            >
              <HeaderSubheader
                title="Product Details"
                text="Enter basic product details and information."
              />

              <div
                className="
                grid grid-flow-row 
                grid-cols-[repeat(auto-fit,_minmax(302px,1fr))]
                gap-4
              "
              >
                <TextInput
                  type="text"
                  name="name"
                  placeholder="Product name"
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={errors.name && touched.name ? errors.name : ''}
                  hasIcon={false}
                  className=""
                />

                <Dropdown
                  loading={loadingCategories}
                  className=""
                  menuClassName="max-h-[200px]"
                  widthClass="!w-full"
                  menuButton={
                    <TextInput
                      type="text"
                      name="category"
                      placeholder="Product category"
                      value={values.category.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        errors.category?._id && touched.category?._id
                          ? errors.category._id
                          : ''
                      }
                      hasIcon={false}
                      readOnly
                      extra={
                        <Icon
                          id={'left-caret'}
                          width={16}
                          height={16}
                          className="-rotate-90 text-sec-black"
                        />
                      }
                      className="!border-divider-gray"
                    />
                  }
                  onClickMenuItem={(selection) => {
                    setFieldValue('category', {
                      _id: selection.value,
                      name: selection.name,
                    })
                  }}
                  menuItems={categories?.data.data.map((category) => {
                    return {
                      name: category.name,
                      value: category._id,
                    }
                  })}
                />

                <Dropdown
                  loading={fetchingVendors}
                  className=""
                  menuClassName="max-h-[200px]"
                  widthClass="!w-full"
                  menuButton={
                    <TextInput
                      type="text"
                      name="vendor"
                      placeholder="Vendor"
                      value={vendorSearch ? vendorSearch : values.vendor.name}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setVendorSearch(e.target.value)
                      }}
                      errors={
                        errors.vendor?._id && touched.vendor?._id
                          ? errors.vendor._id
                          : ''
                      }
                      hasIcon={false}
                      readOnly={false}
                      extra={
                        <Icon
                          id={'left-caret'}
                          width={16}
                          height={16}
                          className="-rotate-90 text-sec-black"
                        />
                      }
                      className="!border-divider-gray"
                    />
                  }
                  onClickMenuItem={(selection) => {
                    setFieldValue('vendor', {
                      _id: selection.value,
                      name: selection.name,
                    })
                    setVendorSearch(undefined)
                  }}
                  menuItems={vendors?.data.docs.map((vendor) => {
                    return {
                      name: vendor.name,
                      value: vendor._id,
                    }
                  })}
                />

                <div className="col-span-full">
                  <TextInput
                    type="textarea"
                    name="description"
                    placeholder="Product description"
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errors={
                      errors.description && touched.description
                        ? errors.description
                        : ''
                    }
                    hasIcon={false}
                    className=""
                  />
                </div>

                <TextInput
                  type="text"
                  name="price"
                  placeholder="Price"
                  value={values.price}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={errors.price && touched.price ? errors.price : ''}
                  hasIcon={false}
                  className=""
                />

                <TextInput
                  type="text"
                  name="quantityInStock"
                  placeholder="Quantity in stock"
                  value={values.quantityInStock}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={
                    errors.quantityInStock && touched.quantityInStock
                      ? errors.quantityInStock
                      : ''
                  }
                  hasIcon={false}
                  className=""
                />

                <div className="col-span-full flex flex-col gap-4 justify-center">
                  <Checkbox
                    checkStatus={values.lowStockAlert}
                    text="Enable low stock notification"
                    handleChange={(status) => {
                      setFieldValue('lowStockAlert', status)
                    }}
                  />

                  {values.lowStockAlert && (
                    <TextInput
                      type="text"
                      name="lowStockQuantity"
                      placeholder="Low stock quantity"
                      value={values.lowStockQuantity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        errors.lowStockQuantity && touched.lowStockQuantity
                          ? errors.lowStockQuantity
                          : ''
                      }
                      hasIcon={false}
                      className="max-w-[302px]"
                    />
                  )}
                </div>
              </div>
            </div>

            {/* TAGS  */}
            <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
              <HeaderSubheader
                title="Product Tags"
                text="Specify product tags"
              />

              <div className="flex flex-row gap-4 items-center flex-wrap">
                {values.tags.map((tag: Tag, idx) => {
                  const key = `tag-${idx}`
                  return (
                    <div
                      key={key}
                      className="text-sm border rounded-md py-2 px-4"
                    >
                      {tag.name}
                    </div>
                  )
                })}
              </div>

              <Button
                primary
                icon="plus"
                size="medium"
                label="Manage tags"
                onClick={() => {
                  handleModalOpen('selectTagsModal')
                }}
                className="!max-w-fit"
              />
            </div>

            {/* VARIANTS  */}
            <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
              <HeaderSubheader
                title="Product Variants"
                text="Specify product variants"
              />

              <Checkbox
                checkStatus={values.isVariant}
                text="This product has variants"
                handleChange={(status) => {
                  setFieldValue('isVariant', status)
                }}
              />

              {values.isVariant && (
                <>
                  <div className="flex flex-row gap-4 items-center flex-wrap">
                    {values.variants.map(
                      (variant: CreatedProductVariant, idx) => {
                        const key = `variant-${idx}`
                        return (
                          <div
                            key={key}
                            className="
                          flex gap-4 items-center 
                          border rounded-md
                          py-2 px-4
                          "
                          >
                            <button
                              type="button"
                              className="text-sm"
                              onClick={() => {
                                setSelectedVariant({
                                  index: idx,
                                  item: variant,
                                })
                                handleModalOpen('addProductVariantModal')
                              }}
                            >
                              {variant.variant.name} - {variant.value}
                            </button>

                            <button
                              type="button"
                              className="
                            w-fit text-danger 
                            bg-danger/5 rounded-full p-2
                            "
                              onClick={() => {
                                const variantsList: string[] = JSON.parse(
                                  JSON.stringify(values.variants ?? []),
                                )
                                setFieldValue(
                                  'variants',
                                  removeElementAtIndex(variantsList, idx),
                                )
                              }}
                            >
                              <Icon id="close-icon" width={10} height={10} />
                            </button>
                          </div>
                        )
                      },
                    )}
                  </div>

                  <Button
                    primary
                    icon="plus"
                    size="medium"
                    label="Add product variants"
                    onClick={() => {
                      handleModalOpen('addProductVariantModal')
                    }}
                    className="!max-w-fit"
                  />
                </>
              )}
            </div>

            {/* COMBOS  */}
            <div className="flex flex-col gap-6 pb-10 border-b border-divider-gray">
              <HeaderSubheader
                title="Product Combo"
                text="Specify combo products"
              />

              <Checkbox
                checkStatus={values.isCombo}
                text="This a combo product"
                handleChange={(status) => {
                  setFieldValue('isCombo', status)
                }}
              />

              {values.isCombo && (
                <>
                  <div className="flex flex-row gap-4 items-center flex-wrap">
                    {values.comboItems.map((item: ComboItem, idx) => {
                      const key = `comboItem-${idx}`
                      return (
                        <div
                          key={key}
                          className="
                          flex gap-4 items-center 
                          border rounded-md
                          py-2 px-4
                          "
                        >
                          <button
                            type="button"
                            className="text-sm"
                            onClick={() => {
                              setSelectedComboItem({ index: idx, item })
                              handleModalOpen('addComboItemModal')
                            }}
                          >
                            {item.name}
                          </button>

                          <button
                            type="button"
                            className="
                            w-fit text-danger 
                            bg-danger/5 rounded-full p-2
                            "
                            onClick={() => {
                              const comboList: string[] = JSON.parse(
                                JSON.stringify(values.comboItems ?? []),
                              )
                              setFieldValue(
                                'comboItems',
                                removeElementAtIndex(comboList, idx),
                              )
                            }}
                          >
                            <Icon id="close-icon" width={10} height={10} />
                          </button>
                        </div>
                      )
                    })}
                  </div>

                  <Button
                    primary
                    icon="plus"
                    size="medium"
                    label="Add combo item"
                    onClick={() => {
                      handleModalOpen('addComboItemModal')
                    }}
                    className="!max-w-fit"
                  />
                </>
              )}
            </div>
          </form>

          <SelectTagsModal
            open={modalOpen && modal === 'selectTagsModal'}
            setOpen={setModalOpen}
            onCloseCallback={() => {
              handleModalClose()
            }}
            onSuccessCallback={(tags) => {
              setFieldValue('tags', tags)
              handleModalClose()
            }}
            selectedTags={values.tags}
          />

          <AddComboItemModal
            open={modalOpen && modal === 'addComboItemModal'}
            setOpen={setModalOpen}
            onCloseCallback={() => {
              handleModalClose()
              setSelectedComboItem(undefined)
            }}
            onSuccessCallback={(item) => {
              const combos: ComboItem[] = JSON.parse(
                JSON.stringify(values.comboItems ?? []),
              )
              if (selectedComboItem) {
                combos[selectedComboItem.index] = item
              } else {
                combos.push(item)
              }
              setFieldValue('comboItems', combos)
              setSelectedComboItem(undefined)
              handleModalClose()
            }}
            comboItem={selectedComboItem?.item}
            mode={selectedComboItem ? 'edit' : 'create'}
          />

          <AddProductVariantModal
            open={modalOpen && modal === 'addProductVariantModal'}
            setOpen={setModalOpen}
            onCloseCallback={() => {
              handleModalClose()
              setSelectedVariant(undefined)
            }}
            onSuccessCallback={(item) => {
              const variants: CreatedProductVariant[] = JSON.parse(
                JSON.stringify(values.variants ?? []),
              )
              if (selectedVariant) {
                variants[selectedVariant.index] = item
              } else {
                variants.push(item)
              }
              setFieldValue('variants', variants)
              setSelectedVariant(undefined)
              handleModalClose()
            }}
            variant={selectedVariant?.item}
            loading={loadingVariants}
            variants={variants?.data.docs ?? []}
            mode={selectedVariant ? 'edit' : 'create'}
          />

          {uploadedImage && (
            <ImageUploadPreviewModal
              image={uploadedImage}
              open={modal === 'imageUploadPreviewModal' && modalOpen}
              setOpen={setModalOpen}
              onCloseCallback={() => {
                setUploadedImage(undefined)
              }}
              onSuccessCallback={(url) => {
                const imagesCopy: string[] = JSON.parse(
                  JSON.stringify(values.images ?? []),
                )
                imagesCopy.push(url)
                setFieldValue('images', imagesCopy)
                setUploadedImage(undefined)
              }}
            />
          )}
        </>
      )}
    </Layout>
  )
}

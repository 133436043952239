import React, { useState } from 'react'

export const TabNav: React.FC<{
  activeTab: string
  setActiveTab: (tab: string) => void
  tabs: { name: string; id: string }[]
  className?: string
}> = ({ activeTab, setActiveTab, tabs, className }) => {
  return (
    <nav className={`relative ${className}`} aria-label="Tabs">
      <div
        className="
        absolute bottom-0 left-0 right-0 
        border-b border-divider-gray
        "
      />

      {tabs.map((tab) => {
        const isActive = activeTab === tab.id
        return (
          <button
            onClick={() => {
              setActiveTab(tab.id)
            }}
            key={tab.name}
            className={`${
              isActive
                ? 'border-black text-black font-semibold'
                : 'border-transparent text-sec-black hover:border-gray-300 hover:text-sec-black'
            }
                  whitespace-nowrap border-b-[3px] 
                  pb-4 px-5 text-sm
                  font-medium
                  transition-all duration-300
                  z-[100]
                  `}
            aria-current={isActive ? 'page' : undefined}
          >
            {tab.name}
          </button>
        )
      })}
    </nav>
  )
}

import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'

import { useFormik } from 'formik'
import { TextInput } from '../../inputs/textInput'
import { FeaturedItemTypes } from '../../../types/types'
import { SelectProductModal } from './selectProductModal'
import { useModalControl } from '../../../hooks/useModalControl'
import { includes } from 'lodash'
import { ImageBanner } from '../../images/imageBanner'
import { ImageUploadPreviewModal } from './ImagePreviewModal'
import {
  useLazyFetchAllListArticlesQuery,
  useLazyFetchListsQuery,
} from '../../../redux-toolkits/lists/list.slice'
import { useLazyGetAllProductTagsQuery } from '../../../redux-toolkits/products/products.slice'
import { CreatedDiscoverItem } from '../../../redux-toolkits/discover/discover.types'
import {
  useAddDiscoverCardMutation,
  useEditDiscoverCardMutation,
} from '../../../redux-toolkits/discover/discover.slice'
import { ImageUploadButton } from '../../button/imageUploadButton'
import Dropdown from '../../inputs/dropdown'
import Icon from '../../icon'

export const ViewFeaturedItemModal = ({
  open,
  setOpen,
  type,
  mode,
  feature,
  successCallback,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  type: FeaturedItemTypes
  mode: 'edit' | 'create'
  feature?: CreatedDiscoverItem
  successCallback: () => void
}) => {
  const [data, setData] = useState<
    {
      name: string
      id: string
    }[]
  >([])
  const [uploadedImage, setUploadedImage] = useState<File>()
  const [selected, setSelected] = useState<string>('')

  const imageRef = useRef<HTMLInputElement | null>(null)

  const { modal, modalOpen, handleModalOpen, setModalOpen, handleModalClose } =
    useModalControl()

  const [fetchLists, { data: lists, isLoading: fetchinglists }] =
    useLazyFetchListsQuery()

  const [fetchArticles, { data: articles, isLoading: fetchingArticles }] =
    useLazyFetchAllListArticlesQuery()

  const [addCard] = useAddDiscoverCardMutation()

  const [editCard] = useEditDiscoverCardMutation()

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      image: '',
      title: '',
      description: '',
      cardTypeId: '',
    },
    onSubmit: (values, { setSubmitting }) => {
      const payload = { ...values, type: type }

      setSubmitting(true)

      feature
        ? editCard({ id: feature._id, payload: payload }).then((data) => {
            setSubmitting(false)
            data.data?.success && successCallback()
          })
        : addCard(payload).then((data) => {
            setSubmitting(false)
            data.data?.success && successCallback()
          })
    },
  })

  const getTypeText = () => {
    switch (type) {
      case 'Event':
        return 'event'
      case 'Product':
        return 'product'
      case 'Article':
        return 'article'
      case 'List':
        return 'list'
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return
    }

    const selectedFile = event.target.files[0]
    setUploadedImage(selectedFile)

    handleModalOpen('imageUploadPreviewModal')
  }

  useEffect(() => {
    open &&
      type === 'Article' &&
      fetchArticles({ query: { skip: 1, limit: 100 } })

    open &&
      type === 'List' &&
      fetchLists({
        skip: 1,
        limit: 100,
      })
  }, [type, open])

  useEffect(() => {
    switch (type) {
      case 'Article':
        setData(
          articles?.data.docs.map((item) => {
            return { name: item.title, id: String(item._id) }
          }) ?? [],
        )

        break
      case 'List':
        setData(
          lists?.data.docs.map((item) => {
            return { name: item.title, id: item._id }
          }) ?? [],
        )
        break
      default:
        break
    }
  }, [lists, articles, open])

  useEffect(() => {
    setFieldValue('title', feature?.title)
    setFieldValue('description', feature?.description)
    setFieldValue('image', feature?.image)
    setFieldValue('cardTypeId', feature?.cardTypeId)
    setSelected(feature?.cardTypeId ?? '')
  }, [feature, open])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        resetForm()
        setData([])
        setUploadedImage(undefined)
        setSelected('')
      }}
      title={`${
        mode === 'create' ? 'Add' : 'Modify'
      } featured ${getTypeText()}`}
    >
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="relative">
          <ImageBanner
            imageUrl={values.image}
            loading={false}
            className="max-h-[200px]"
          />

          <ImageUploadButton handleChange={handleFileChange} />
        </div>

        <TextInput
          name="title"
          value={values.title}
          placeholder="Title"
          onChange={handleChange}
          onBlur={handleBlur}
          hasIcon={false}
          className="!border-divider-gray"
        />

        <TextInput
          name="description"
          value={values.description}
          placeholder="Description"
          onChange={handleChange}
          onBlur={handleBlur}
          hasIcon={false}
          className="!border-divider-gray"
        />

        {['Product', 'Event'].includes(type) && (
          <div>
            <span className="text-base font-medium capitalize">
              {getTypeText()} Id:{' '}
            </span>
            <span>{selected || 'Not selected'}</span>
          </div>
        )}

        {['Article', 'List'].includes(type) ? (
          <Dropdown
            loading={fetchingArticles || fetchingArticles}
            className=""
            menuClassName="max-h-[200px]"
            widthClass="!w-full"
            menuButton={
              <TextInput
                type="text"
                name="category"
                placeholder="List category"
                value={data.find((item) => item.id === values.cardTypeId)?.name}
                onBlur={handleBlur}
                onChange={handleChange}
                errors={errors.cardTypeId ? errors.cardTypeId : ''}
                hasIcon={false}
                readOnly
                extra={
                  <Icon
                    id={'left-caret'}
                    width={16}
                    height={16}
                    className="-rotate-90 text-sec-black"
                  />
                }
                className="!border-divider-gray"
              />
            }
            onClickMenuItem={(selection) => {
              setFieldValue('cardTypeId', selection.value)
            }}
            menuItems={data.map((item) => {
              return {
                name: item.name,
                value: item.id,
              }
            })}
          />
        ) : (
          <Button
            label={`Choose ${getTypeText()}`}
            type="button"
            className="!w-fit"
            onClick={() => {
              handleModalOpen('selectProductModal')
            }}
            size="medium"
            icon="plus"
          />
        )}

        <Button
          label="Save"
          type="submit"
          className="mt-10"
          disabled={isSubmitting}
          loading={isSubmitting}
          primary
        />
      </form>

      {modal === 'selectProductModal' && modalOpen && (
        <SelectProductModal
          open={modal === 'selectProductModal' && modalOpen}
          setOpen={setModalOpen}
          onCloseCallback={() => {}}
          onSuccessCallback={(product) => {
            setFieldValue('cardTypeId', product._id)
            setSelected(product.name)
            handleModalClose()
          }}
        />
      )}

      {uploadedImage && (
        <ImageUploadPreviewModal
          image={uploadedImage}
          open={modal === 'imageUploadPreviewModal' && modalOpen}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            setUploadedImage(undefined)
          }}
          onSuccessCallback={(url) => {
            setFieldValue('image', url)
          }}
        />
      )}
    </ModalComponent>
  )
}

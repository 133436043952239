import React from 'react'
import { registerables, Chart } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { PRIMARY_COLOR } from '../../constants'

interface Props {
  labels: string[]
  data: number[]
  width?: number
  height?: number
  responsive: boolean
}

Chart.register(...registerables)

const BarChart: React.FC<Props> = ({
  data,
  labels,
  width = 500,
  height = 200,
  responsive,
}) => {
  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            label: 'Amount',
            backgroundColor: PRIMARY_COLOR,
            borderWidth: 0,
            barThickness: 12,
            borderRadius: 6,
            data,
          },
        ],
      }}
      options={{
        responsive,
        scales: {
          x: {
            grid: {
              display: true,
              lineWidth: 3,
              color: '#555555',
            },
            border: {
              display: false,
            },
            display: false,
          },
          y: {
            grid: {
              display: true,
            },
            display: true,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      width={width}
      height={height}
    />
  )
}

export default BarChart

import React, { useEffect, useState } from 'react'
import ModalComponent from '..'
import { Button } from '../../button'
import { useUploadImageMutation } from '../../../redux-toolkits/misc/misc.slice'
import {
  useGetAllProductsQuery,
  useLazyGetAllProductsQuery,
} from '../../../redux-toolkits/products/products.slice'
import { Product, QueryProps } from '../../../types/types'
import { TextInput } from '../../inputs/textInput'
import { debounce } from 'lodash'
import { LoadingOval } from '../../spinner/Spinner'
import { PRIMARY_COLOR } from '../../../constants'
import { formatCurrency } from '../../../helpers'
import { EmptyState } from '../../emptyState/emptyState'

const handleSearch = (searchFunction: (val: any) => void, query: any) => {
  searchFunction(query)
}

const handleDebouncedSearch = debounce(handleSearch, 800)

const defaultquery = {
  search: '',
  page: '1',
  pageSize: '10',
  skip: '0',
  limit: '10',
}

export const SelectProductModal = ({
  open,
  setOpen,
  onCloseCallback,
  onSuccessCallback,
}: {
  open: boolean
  setOpen: (val: boolean) => void
  onCloseCallback?: () => void
  onSuccessCallback?: (val: Product) => void
}) => {
  const [queryParam, setQueryParam] = useState<QueryProps>(defaultquery)

  const [getAllProducts, { data, isFetching }] = useLazyGetAllProductsQuery()

  useEffect(() => {
    open && handleDebouncedSearch(getAllProducts, queryParam)
  }, [queryParam.search, open])

  return (
    <ModalComponent
      open={open}
      setOpen={setOpen}
      onCloseCallback={() => {
        onCloseCallback && onCloseCallback()
        setQueryParam(defaultquery)
      }}
      title="Product Search"
    >
      <div
        className="
        h-screen max-h-[500px]
        flex flex-col gap-4
      "
      >
        <TextInput
          name="search"
          onChange={(e) => {
            setQueryParam({ ...queryParam, search: e.target.value })
          }}
          placeholder="Type to search..."
          iconSvg="search"
          value={queryParam.search}
          className="!bg-gray-bg !rounded-[32px] 
            !border-transparent !text-sec-black !py-3"
        />

        {/* Products  */}
        <div className="flex-1 overflow-y-scroll">
          {isFetching ? (
            <div className="w-full flex justify-center pt-10">
              <LoadingOval
                loaderWidth="25"
                loaderHeight="25"
                color={PRIMARY_COLOR}
              />
            </div>
          ) : (
            <div>
              {data?.data?.data?.length && data?.data?.data?.length > 0 ? (
                <div className="flex flex-col">
                  {data?.data?.data?.map((product, idx) => {
                    const key = `procuct-selection-option-${idx}-${product._id}`
                    return (
                      <div
                        key={key}
                        className="
                        flex gap-2 items-center
                        border-b border-divider-gray
                        py-2
                        first:pt-0
                      "
                        onClick={() => {
                          onSuccessCallback && onSuccessCallback(product)
                        }}
                      >
                        <img
                          alt={product.name}
                          src={product.image}
                          className="w-[50px] h-[50px] object-cover rounded-md overflow-hidden"
                        />
                        <div
                          className="
                          flex-1 
                        "
                        >
                          <span
                            className="
                            group
                            cursor-pointer
                            relative
                            text-sm text-black
                            font-normal
                            "
                          >
                            {product.name}

                            <hr
                              className="
                                absolute
                                w-0 opacity-0
                                group-hover:w-full group-hover:opacity-100
                                transition-all duration-300
                                border-[1.5px] border-black
                                rounded-full
                            "
                            />
                          </span>
                        </div>
                        <div className="text-sm text-sec-black">
                          {formatCurrency(product.price)}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <EmptyState text="No products found" />
              )}
            </div>
          )}
        </div>
      </div>
    </ModalComponent>
  )
}

import React, { useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Icon from '../icon'
import { Logo } from '../logo'
import { Store, Wallet } from 'lucide-react'
import { CURRENT_PAGE_NUMBER, NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'

interface SubMenu {
  title: string
  href: string
}
const navItems: Array<{
  id: number
  text: string
  icon: string
  dimension: number
  href: string
  hidden?: string
  subMenu: SubMenu[] | []
}> = [
  {
    id: 1,
    text: 'Dashboard',
    icon: 'dashboard-icon',
    dimension: 23,
    href: '/dashboard',
    subMenu: [],
  },
  {
    id: 567,
    text: 'Vendors',
    icon: 'vendor',
    dimension: 23,
    href: '/vendors',
    hidden: '',
    subMenu: [],
  },
  {
    id: 3,
    text: 'Products',
    icon: 'product-icon',
    dimension: 23,
    href: '',
    hidden: '',
    subMenu: [
      {
        title: 'All Products',
        href: '/products',
      },
      {
        title: 'Everyday Lists',
        href: '/lists/curated',
      },
      {
        title: 'Articles',
        href: '/lists/articles',
      },
      {
        title: 'Discover',
        href: '/discover',
      },
    ],
  },
  {
    id: 2,
    text: 'Orders',
    icon: 'order-icon',
    dimension: 23,
    href: `/orders?status=all&skip=${CURRENT_PAGE_NUMBER}&limit=${NUMBER_OF_ITEMS_PER_PAGE}`,
    subMenu: [],
  },

  {
    id: 643,
    text: 'Transactions',
    icon: 'trans-icon',
    dimension: 23,
    href: '/transactions',
    hidden: '',
    subMenu: [],
  },
  {
    id: 400,
    text: 'Waitlist',
    icon: 'waitlist',
    dimension: 23,
    href: '/waitlist',
    hidden: '',
    subMenu: [],
  },
  {
    id: 8,
    text: 'Settings',
    icon: 'gear-icon',
    dimension: 23,
    href: '/settings',
    hidden: '',
    subMenu: [],
  },
]

const SideBar: React.FC<{
  navOpen?: boolean
  setNavOpen?: (val: boolean) => void
}> = ({ navOpen, setNavOpen }) => {
  const [subMenuOpen, setSubMenuOpen] = useState<{
    state: boolean
    index: number | null
  }>({
    state: false,
    index: null,
  })

  const { pathname } = useLocation()

  return (
    <div
      className={`
      bg-frost
      h-full
      border-r-divider-gray border-r
      px-4 md:px-10 py-4 md:py-10
      overflow-y-scroll
      flex flex-col
      w-full max-w-[320px]
      absolute xl:relative
      right-[100%] xl:right-auto ${navOpen && '!left-0'}
      !z-[998]
      `}
    >
      {/* Main  */}
      <div className="text-base">
        <div className="flex flex-col text-pry-gray">
          {navItems.map(({ href, id, text, icon, hidden, subMenu }) => (
            <NavLink
              to={href}
              key={id}
              onClick={(e) => {
                if (subMenu.length > 0) {
                  e.preventDefault()
                  setSubMenuOpen({
                    state: !subMenuOpen.state,
                    index: id,
                  })
                } else {
                  setNavOpen && setNavOpen(false)
                }
              }}
            >
              {({ isActive }) => {
                const isSubMenuActive = subMenu.some(({ href }) =>
                  pathname.includes(href),
                )
                return hidden ? undefined : (
                  <>
                    <div
                      className={`
                        flex items-center
                        gap-4 p-4
                        ${
                          (isActive && subMenu.length === 0) ||
                          (isSubMenuActive && subMenu.length > 0)
                            ? 'text-primary bg-primary/5 font-semibold'
                            : ''
                        }
                        rounded-[8px]
                        hover:text-primary 
                        transition-all duration-500
                        `}
                    >
                      <Icon id={icon} width={18} height={18} />

                      <div className="flex-1">{text}</div>

                      {subMenu.length > 0 && (
                        <Icon
                          id="left-caret"
                          width={18}
                          height={12}
                          className={`transition-all duration-300 ${
                            subMenuOpen.state && subMenuOpen.index === id
                              ? 'rotate-90'
                              : '-rotate-90'
                          }`}
                        />
                      )}
                    </div>

                    {subMenuOpen.state && subMenuOpen.index === id ? (
                      <div
                        className="
                      flex flex-col
                      pl-[50px] pr-4
                      "
                      >
                        {subMenu.map(({ href, title }, idx) => {
                          const key = `${title}-subMenu-${idx}`
                          return (
                            <NavLink
                              to={href}
                              key={key}
                              className={`
                                py-4
                              hover:text-primary transition-all duration-300
                              `}
                              onClick={() => {
                                setNavOpen && setNavOpen(false)
                              }}
                            >
                              {({ isActive }) => (
                                <span
                                  className={`${isActive && 'text-primary'}`}
                                >
                                  {title}
                                </span>
                              )}
                            </NavLink>
                          )
                        })}
                      </div>
                    ) : undefined}
                  </>
                )
              }}
            </NavLink>
            // <div key={id} className="group relative">
            //   <li
            //     className={`
            //       flex justify-between items-center ${hidden} ${
            //         pathname.includes(text.toLowerCase())
            //           ? 'bg-gray-200 p-3 rounded-md text-light-blue'
            //           : ''
            //       } `}
            //   >
            //     <div className="flex justify-start items-center  space-x-2">
            //       {href !== '/vendors' && href !== '/transactions' && (
            //         <Icon id={icon} height={dimension} width={dimension} />
            //       )}
            //       {href === '/vendors' && <Store height={23} width={23} />}
            //       {href === '/transactions' && (
            //         <Wallet height={23} width={23} />
            //       )}
            //       <Link to={href}>{text}</Link>
            //     </div>
            //     {subMenu.length > 0 ? (
            //       <Icon id="arrow-down-icon" height={14} width={14} />
            //     ) : (
            //       ''
            //     )}
            //   </li>
            //   {subMenu.length > 0 ? (
            //     <div className="hidden group-hover:block text-sm absolute bg-white z-10 w-full shadow-md p-4">
            //       <ul className=" space-y-5">
            //         {subMenu.map(({ title, href }) => (
            //           <li
            //             key={href}
            //             className="flex justify-start items-center space-x-2 hover:text-light-blue"
            //           >
            //             <Link to={href}>{title}</Link>
            //           </li>
            //         ))}
            //       </ul>
            //     </div>
            //   ) : (
            //     ''
            //   )}
            // </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SideBar

import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { PageNotFound } from './features/404'
import ForgotPasswordPage from './features/auth/forgot-password'
import LoginPage from './features/auth/login'
import ResetPasswordPage from './features/auth/reset-password'
import { DashboardPage } from './features/dashboard'
import { OrdersPage } from './features/orders'
import { CreateUserPage } from './features/users/CreateUserPage'
import NoneProtectedRoute from './route/NoneProtectedRoute'
import ProtectedRoute from './route/ProtectedRoute'

import { SingleCuratedListPage } from './features/lists/curated/SingleCuratedList'
import { UsersPage } from './features/users'
import Settings from './features/settings'
import Vendors from './features/vendors'
import Transactions from './features/transactions/TransactionsPage'
import OrderDetailsPage from './features/orders/OrderDetailsPage'
import WaitListPage from './features/waitlist'

import { CuratedListsPage } from './features/lists/curated/CuratedListsPage'
import { SingleListArticle } from './features/lists/lists-articles/SingleListArticle'
import CreateList from './features/lists/curated/CreateList'
import { ListArticles } from './features/lists/lists-articles/ListArticles'
import ProfilerWrapper from './components/profiler/ProfilerWrapper'
import { DiscoverPage } from './features/discover'
import { Products } from './features/products'
import { ConfigureLists } from './features/lists/curated/ConfigureList'
import { CreateProductPage } from './features/products/CreateProduct'

const App = () => {
  return (
    <ProfilerWrapper id="appRoot">
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/orders/:id" element={<OrderDetailsPage />} />
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/waitlist" element={<WaitListPage />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/new" element={<CreateProductPage />} />
            <Route path="/products/view/:id" element={<CreateProductPage />} />
            <Route path="/users/new" element={<CreateUserPage />} />
            <Route path="/lists/articles" element={<ListArticles />} />
            <Route
              path="/lists/articles/view/:id"
              element={<SingleListArticle mode="edit" />}
            />
            <Route
              path="/lists/articles/create"
              element={<SingleListArticle mode="create" />}
            />
            <Route path="/lists/curated" element={<CuratedListsPage />} />
            <Route path="/lists/curated/new" element={<ConfigureLists />} />
            <Route
              path="/lists/curated/edit/:id"
              element={<ConfigureLists />}
            />
            <Route
              path="/lists/curated/view/:id"
              element={<SingleCuratedListPage />}
            />
            <Route path="/discover" element={<DiscoverPage />} />
            <Route path="/users" element={<UsersPage />} />
            <Route path="/settings" element={<Settings />} />
          </Route>

          <Route element={<NoneProtectedRoute />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </ProfilerWrapper>
  )
}

export default App

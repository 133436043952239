import { apiSlice } from '../api/apiSlice'
import type { OrdersAnalyticsResponse } from '../../redux-toolkits/orders/orders.type'

export const orderAnalyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderAnalytics: builder.query<OrdersAnalyticsResponse, void>({
      query: () => ({
        url: `/order/analytics?duration=year`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetOrderAnalyticsQuery } = orderAnalyticsApiSlice

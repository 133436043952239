import React, { useEffect, useRef, useState } from 'react'
import Icon from '../icon'

export const Checkbox = ({
  checkStatus,
  handleChange = () => {},
  text = '',
}: {
  checkStatus: boolean
  handleChange: (status: boolean) => void
  text?: string
}) => {
  const [checked, setChecked] = useState(checkStatus)

  const checkBoxRef = useRef<HTMLInputElement | null>(null)

  return (
    <div
      className="w-fit cursor-pointer flex items-center gap-4"
      onClick={() => {
        checkBoxRef?.current?.click()
      }}
    >
      <Icon
        id={checked ? 'checkbox-active' : 'checkbox-inactive'}
        width={16}
        height={16}
      />

      <span className="block">{text}</span>
      <input
        type="checkbox"
        className="hidden"
        ref={checkBoxRef}
        checked={checked}
        onChange={(e) => {
          const initialCheckStatus = e.target.checked
          handleChange(initialCheckStatus)
          setChecked(initialCheckStatus)
        }}
      />
    </div>
  )
}

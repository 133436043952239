import React, { useState } from 'react'
import { TableComponent } from '../../../components/table'
import { useNavigate } from 'react-router'
import {
  useFetchListsQuery,
  usePinUnpinListMutation,
} from '../../../redux-toolkits/lists/list.slice'
import { format } from 'date-fns'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../../constants'
import clsx from 'clsx'
import { SingleListDoc } from '../../../redux-toolkits/lists/list.types'
import { Button } from '../../../components/button'
import Dropdown from '../../../components/inputs/dropdown'
import Icon from '../../../components/icon'
import ModalComponent from '../../../components/modal'

const CuratedListsTable: React.FC = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [editing, setEditing] = useState<SingleListDoc>()
  const [query, setQuery] = useState({
    skip: 1, //actually "page"
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })

  const { data, isFetching, refetch } = useFetchListsQuery({
    skip: query.skip,
    limit: query.limit,
  })

  return (
    <div>
      <div className="flex justify-end mb-10">
        <Button
          type="button"
          primary
          size="medium"
          onClick={() => {
            navigate('/lists/curated/new')
          }}
          label="Create list"
          icon="plus"
        />
      </div>

      <TableComponent
        headers={['Title', 'Category', 'Status', 'Date created', 'Author', ' ']}
        rows={
          data?.data.docs.map((listDetail) => {
            return {
              id: listDetail._id,
              content: [
                <div key="" className="flex itemx-center gap-2">
                  {listDetail.pinned && (
                    <Icon
                      id="pin"
                      width={20}
                      height={20}
                      className="text-primary"
                    />
                  )}
                  {listDetail.title}
                </div>,
                <div key="">{listDetail?.category?.name ?? ''}</div>,
                <div key="">{listDetail.status}</div>,
                <div key="">{format(listDetail.createdAt, 'dd-MM-yyyy')}</div>,
                <div key="">{`${listDetail.author.firstname} ${listDetail.author.lastname}`}</div>,
                <Dropdown
                  key={`${listDetail._id}-controls`}
                  menuButton={
                    <Icon id="ellipses" height={18} width={18} className="" />
                  }
                  onClickMenuItem={() => {}}
                  menuItems={[
                    {
                      name: (
                        <button
                          className="disabled:opacity-30 w-full text-left"
                          onClick={(e): any => {
                            setEditing(listDetail)
                            setOpen(true)
                          }}
                        >
                          {listDetail.pinned ? 'UnPin' : 'Pin List'}
                        </button>
                      ),
                      value: '',
                    },
                    {
                      name: (
                        <button
                          type="button"
                          onClick={() => {
                            navigate(`/lists/curated/view/${listDetail._id}`)
                          }}
                          className="w-full text-left"
                        >
                          View details
                        </button>
                      ),
                      value: '',
                    },
                  ]}
                />,
              ],
            }
          }) ?? []
        }
        name="lists-table"
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.docs?.length)}
        currentPage={query.skip}
        totalDataCount={data?.data?.total}
        pageLimit={query.limit}
        pageRangeDisplayed={3}
        onPageChange={(pageNo) => {
          setQuery({ ...query, skip: pageNo })
        }}
      />

      <PinListModal
        open={open}
        closeModal={() => setOpen(false)}
        list={editing as SingleListDoc}
        successCallback={() => {
          refetch()
        }}
      />
    </div>
  )
}

const PinListModal: React.FC<{
  open: boolean
  closeModal: () => void
  list: SingleListDoc
  successCallback: () => void
}> = ({ open, closeModal, list, successCallback }) => {
  const [pinUnpinList, { isLoading }] = usePinUnpinListMutation()

  return (
    <>
      <ModalComponent
        open={open}
        setOpen={() => {}}
        onCloseCallback={() => {
          closeModal()
        }}
        title={`${list?.pinned ? 'Unpin' : 'Pin'} "${list?.title}"`}
      >
        <span className="text-lg font-normal">Confirm to proceed</span>

        <div className="grid grid-cols-2 gap-4 mt-20">
          <Button label={'Cancel'} onClick={closeModal} />
          <Button
            label={'Confirm'}
            primary
            onClick={async () => {
              await pinUnpinList({
                body: { pinned: !list.pinned },
                id: list._id,
              }).then((resp) => {
                if (resp.data?.success) {
                  successCallback()
                }
              })
              closeModal()
            }}
            loading={isLoading}
          />
        </div>
      </ModalComponent>
    </>
  )
}

export default React.memo(CuratedListsTable)

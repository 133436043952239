import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { format } from 'date-fns'
import { formatCurrency } from '../../helpers'
import { Product } from '../../types/types'
import { Button } from '../../components/button'
import {
  useAddProductsToListMutation,
  useFetchListsQuery,
} from '../../redux-toolkits/lists/list.slice'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { TableComponent } from '../../components/table'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { Link, useNavigate } from 'react-router-dom'
import { UpdateItemStockModal } from '../../components/modal/app_modals/updateItemStockmodal'
import { useModalControl } from '../../hooks/useModalControl'

interface Props {
  productList: Product[]
  currentPage?: number
  totalDataCount?: number
  pageLimit?: number
  onPageChange?: (page: number) => void
  loading?: boolean
}

const ProductsTable: React.FC<Props> = ({
  productList,
  currentPage,
  totalDataCount,
  pageLimit,
  onPageChange,
  loading,
}) => {
  const [selected, setSelected] = useState<Product>()

  const navigate = useNavigate()

  const { modal, modalOpen, setModalOpen, handleModalOpen, handleModalClose } =
    useModalControl()

  return (
    <>
      <TableComponent
        headers={[' ', 'Name', 'Price', 'Category', 'Creation Date', '']}
        rows={productList.map((product) => {
          return {
            id: product._id,
            content: [
              <img
                key={`${product._id}-image`}
                height={50}
                width={50}
                src={product.image}
                alt="product"
                className="min-w-[50px] overflow-hidden rounded-md"
              />,
              <Link
                key={`${product._id}-name`}
                to={`/products/view/${product._id}`}
                className="text-primary"
              >
                {product.name}
              </Link>,
              formatCurrency(product.price),
              product.category.name,
              format(new Date(product.createdAt), 'yyyy-MM-dd'),
              <Dropdown
                key={`${product._id}-controls`}
                menuButton={
                  <Icon id="ellipses" height={18} width={18} className="" />
                }
                onClickMenuItem={() => {}}
                menuItems={[
                  {
                    name: (
                      <button
                        className="disabled:opacity-30 w-full text-left"
                        onClick={(): any => {
                          navigate(`/products/view/${product._id}`)
                        }}
                      >
                        Edit
                      </button>
                    ),
                    value: '',
                  },
                  {
                    name: (
                      <button
                        className="disabled:opacity-30 w-full text-left"
                        onClick={(): any => {
                          setSelected(product)
                          handleModalOpen('updateItemStockModal')
                        }}
                      >
                        Manage stock
                      </button>
                    ),
                    value: '',
                  },
                ]}
              />,
            ],
          }
        })}
        loading={loading}
        currentPage={currentPage}
        totalDataCount={totalDataCount}
        pageLimit={pageLimit}
        onPageChange={onPageChange}
      />

      {selected && (
        <UpdateItemStockModal
          open={modalOpen && modal === 'updateItemStockModal'}
          setOpen={setModalOpen}
          onCloseCallback={() => {
            handleModalClose()
            setSelected(undefined)
          }}
          onSuccessCallback={() => {
            handleModalClose()
            setSelected(undefined)
          }}
          product={selected}
        />
      )}
    </>
  )
}

export default ProductsTable

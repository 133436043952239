import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type AuthResponse } from '../user/user.type'
import Cookie from 'js-cookie'
import { COOKIE_KEY } from '../../constants'

const initialState: AuthResponse = {
  user: null,
  signedIn: false,
  token: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateAuthStatus: (state: AuthResponse, action: PayloadAction<boolean>) => {
      state.signedIn = action.payload
    },
    setAuthUserData: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.signedIn = true
      Cookie.set(COOKIE_KEY, JSON.stringify(action.payload))
    },
    signOut: (state: AuthResponse) => {
      state.user = initialState.user
      state.signedIn = false
      Cookie.remove(COOKIE_KEY)
    },
    updateUserNoAuth: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { updateAuthStatus, setAuthUserData, signOut } = authSlice.actions
export default authSlice.reducer

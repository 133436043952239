import React, { useState } from 'react'
import { useFetchListCategoriesQuery } from '../../redux-toolkits/lists/list.slice'
import { TableComponent } from '../../components/table'
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants'

import { PageHeader } from '../../components/typography/PageHeader'
import { Button } from '../../components/button'
import { CountCardContainer } from '../../components/wrapper/CountCardsContainer'
import CountCard from '../orders/CountCard'
import { useModalControl } from '../../hooks/useModalControl'
import Dropdown from '../../components/inputs/dropdown'
import Icon from '../../components/icon'
import { ListCategoryDoc } from '../../redux-toolkits/lists/list.types'
import { AddTagModal } from '../../components/modal/app_modals/addTagModal'
import { useGetAllProductTagsQuery } from '../../redux-toolkits/products/products.slice'
import { Tag } from '../../redux-toolkits/products/products.type'

const Tags: React.FC = () => {
  const [query, setQuery] = useState({
    skip: 0,
    limit: NUMBER_OF_ITEMS_PER_PAGE,
  })

  const [selectedTag, setSelectedTag] = useState<Tag>()
  const [mode, setMode] = useState<'create' | 'edit'>('create')

  const { setModalOpen, modalOpen, handleModalOpen, handleModalClose, modal } =
    useModalControl()

  const { data, isFetching, refetch } = useGetAllProductTagsQuery({
    skip: query.skip.toString(),
    limit: query.limit.toString(),
  })

  return (
    <div>
      <PageHeader title="Tags" />

      <div className="w-full flex justify-end">
        <Button
          icon="plus"
          size="medium"
          label="Add new tag"
          primary
          onClick={() => {
            setMode('create')
            handleModalOpen('addTagModal')
          }}
        />
      </div>

      <CountCardContainer>
        <CountCard
          isCurrency={false}
          text="Total tags count"
          count={Number(data?.data.totalItems ?? 0)}
        />
      </CountCardContainer>

      <TableComponent
        headers={['Name', 'Description', 'Tag ID', ' ']}
        rows={
          data?.data?.data.length
            ? data.data.data.map((singleTag) => {
                return {
                  id: singleTag._id,
                  content: [
                    <div key="">{singleTag.name}</div>,
                    <span
                      key=""
                      className="line-clamp-1 text-ellipsis max-w-[250px]"
                    >
                      {singleTag.description}
                    </span>,
                    <div key="">{singleTag._id}</div>,
                    <Dropdown
                      key={`${singleTag._id}-controls`}
                      menuButton={
                        <Icon
                          id="ellipses"
                          height={18}
                          width={18}
                          className=""
                        />
                      }
                      onClickMenuItem={() => {}}
                      menuItems={[
                        {
                          name: (
                            <button
                              className="disabled:opacity-30 w-full text-left"
                              onClick={(): any => {
                                setMode('edit')
                                handleModalOpen('addTagModal')
                                setSelectedTag(singleTag)
                              }}
                            >
                              Edit
                            </button>
                          ),
                          value: '',
                        },
                      ]}
                    />,
                  ],
                }
              })
            : []
        }
        name="tags-table"
        loading={isFetching}
        isEmpty={[undefined, 0].includes(data?.data?.data.length)}
        currentPage={query.skip / query.limit + 1}
        totalDataCount={Number(data?.data.totalItems) ?? 0}
        pageLimit={query.limit}
        onPageChange={(number) => {
          setQuery({ ...query, skip: (number - 1) * query.limit })
        }}
      />

      <AddTagModal
        open={modalOpen && modal === 'addTagModal'}
        setOpen={setModalOpen}
        onCloseCallback={() => {
          handleModalClose()
          setSelectedTag(undefined)
        }}
        onSuccessCallback={() => {
          setSelectedTag(undefined)
          refetch()
        }}
        mode={mode}
        tag={selectedTag}
      />
    </div>
  )
}

export default Tags
